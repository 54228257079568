@import "../../variables";

.no-match-page {
  margin-top: 120px;
  height: 900px;
  background-image: url('../../assets/Images/bg-user.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: bottom;
  background-position-x: left;
  font-family: map-get($fonts, black);

  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-404 {
  padding-top: 5rem;
  margin: 0;
  text-align: center;
  // padding-bottom: 2rem;
  // padding-left: 5rem;
}

.back-home {
  background-color: map-get($colors, secondary);
  color: white; 
  border-radius: 100px;
  height: 50px !important;
  width: 200px !important;
  border: none;
  margin-top: 2rem;
}

.back-home:hover {
  -webkit-box-shadow: 2px 2px 8px 0px rgba(44,64,47,0.35);
  -moz-box-shadow: 2px 2px 8px 0px rgba(44,64,47,0.35);
  box-shadow: 2px 2px 8px 0px rgba(44,64,47,0.35);

  a {
    text-decoration: none;
    color: white;
  }
}

.causas-titulo {
  font-size: 20px;
  font-weight: bold;
  color: white;
  text-align: center;
}

.causas {
  padding: 0 !important;
  list-style: none;
  list-style: map-get($colors, primaryLight);
  color: #f1f1f1;
  text-align: center;
}

.moved {
  margin-left: 20rem;
  position: absolute;
}

.never-existed {
  animation: blinker linear 4.5s infinite;
  animation-delay: 3s;
}

@keyframes blinker {
  35% {
    opacity: 0;
  }

  95% {
    opacity: 0;
  }
}


@media only screen and (max-width: 1700px) {
  .no-match-page {
    background-size: cover;
  }
}

@media only screen and (max-width: 992px) {
  .no-match-page {
    height: 750px;
  }

  .title-404 {
    font-size: 30px;
  }

  .illustra-404 {
    width: 70%;
    height: auto;
  }

  .causas-container {
    margin-top: 1rem;
  }

  .moved {
    margin-left: 14rem;
  }
}

@media only screen and (max-width: 768px) {
  .title-404 {
    font-size: 26px;
  }
}

@media only screen and (max-width: 600px) {
  .title-404 {
    font-size: 24px;
  }

  .causas-titulo {
    font-size: 18px;
  }
}

@media only screen and (max-width: 556px) {
  .no-match-page {
    height: 700px;
  }

  .title-404 {
    font-size: 22px;
  }

  .moved {
    margin-top: 2.5rem;
    margin-left: 11rem;
  }
}

@media only screen and (max-width: 492px) {
  .no-match-page {
    height: 650px;
  }
  
  .title-404 {
    font-size: 20px;
    margin: 0 10px;
  }

  .causas-titulo {
    font-size: 16px;
  }

  .causas {
    font-size: 14px;
  }

  .moved {
    margin-top: 10.5rem;
    margin-left: 3rem;
  }
}