// IMPORTACOES DEFAULT PARA TODO O PROJETO
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,900;1,400;1,900&display=swap');


// DEFINE AS CORES PRIMARIAS PARA O PROJETO
$colors: (
  primary: #94CA58,
  primaryLight: #C9E4AB,
  primaryHover: #75a044,
  secondary: #243352,
  secondaryHover: #142953,
  success: #5cb85c,
  danger: #d9534f,
  dark: #333,
  darkHover: #666,
  medium: #AFAFAF,
  light: #D9D9D9,
  ultraLight: #f1f1f1
);

$fonts: (
  black: 'Fira Sans',
  bold: 'Merriweather',
);

$theme-colors: (
    "primary": #94CA58,
    "secondary": #243352,
    "info": teal,
    "danger": #d9534f
);

@import "~bootstrap/scss/bootstrap";

@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

.btn-primary-action {
  background-color: map-get($colors, primary) !important;
  font-family: map-get($fonts, black);
  color: map-get($colors, secondary) !important;
  border: none !important;
  border-radius: 200px !important;
  font-size: 1rem !important;
  min-width: 130px;
}
.btn-primary-action:hover {
  box-shadow: 2px 2px 9px rgba(102, 102, 102, 0.4) !important;
}

.btn-secondary-action {
  font-family: map-get($fonts, black) !important;
  color: map-get($colors, secondary) !important;
  font-size: 1rem !important;
}
.btn-secondary-action:hover {
  color: map-get($colors, primaryHover) !important;
  text-decoration: none !important;
}

.btn-outlined-action {
  border: 1px solid map-get($colors, primaryHover) !important;
  color: map-get($colors, primaryHover) !important;
  border-radius: 200px !important;
  width: auto !important;
}
.btn-outlined-action:hover {
  background-color: map-get($colors, primaryHover) !important;
  color: white !important;
}

.btn-link-action {
  color: map-get($colors, secondary) !important;
  text-decoration: underline;
}
.btn-link-action:hover {
  color: map-get($colors, primary) !important;
}

.icon {
  margin-right: 5px !important;
  padding: 0px !important;
}

.shimmer {
  padding: 15px 0px;
}

.msgError {
  margin: 5px 0px 10px 0px;
}

.heading-border {
  // width: 200px;
  width: max-content;
  background-image: url('../src/assets/Images/span-bg.png');
  background-position-x: center;
  background-repeat: no-repeat;
  padding-bottom: 15px;
}

.color-primaryHover {
  color: map-get($colors, primaryHover) !important;
}

.color-secondary {
  color: map-get($colors, secondary) !important;
}

.loading-content {
  font-family: map-get($fonts, black);
  color: map-get($colors, secondary);
  margin: 30px;
}

.badge-primary {
  color: map-get($colors, secondary) !important;
}

body {
  margin: auto;
}

h1 {
  font-family: map-get($fonts, black);
  color: map-get($colors, secondary);
  font-size: 1.8rem;
  font-weight: bold !important;
}

h2 {
  font-family: map-get($fonts, black);
  color: map-get($colors, secondary);
  font-size: 1.3rem;
  font-weight: bold !important;
}

h3 {
  font-family: map-get($fonts, black);
  color: map-get($colors, secondary) !important;
  font-size: 18px;
  font-weight: bold;
}

h4 {
  font-family: map-get($fonts, black) !important;
  font-weight: bold !important;
}



 // Restore Bootstrap 3 "hidden" utility classes.

/* Breakpoint XS */
@media (max-width: 575px)
{
  .hidden-xs-down, .hidden-sm-down, .hidden-md-down, .hidden-lg-down, .hidden-xl-down, 
  .hidden-xs-up, 
  .hidden-unless-sm, .hidden-unless-md, .hidden-unless-lg, .hidden-unless-xl
  {
    display: none !important;
  }
}

/* Breakpoint SM */
@media (min-width: 576px) and (max-width: 767px)
{
  .hidden-sm-down, .hidden-md-down, .hidden-lg-down, .hidden-xl-down, 
  .hidden-xs-up, .hidden-sm-up, 
  .hidden-unless-xs, .hidden-unless-md, .hidden-unless-lg, .hidden-unless-xl
  {
    display: none !important;
  } 
}

/* Breakpoint MD */
@media (min-width: 768px) and (max-width: 991px)
{
  .hidden-md-down, .hidden-lg-down, .hidden-xl-down, 
  .hidden-xs-up, .hidden-sm-up, .hidden-md-up, 
  .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-lg, .hidden-unless-xl
  {
    display: none !important;
  } 
}

/* Breakpoint LG */
@media (min-width: 992px) and (max-width: 1199px)
{
  .hidden-lg-down, .hidden-xl-down, 
  .hidden-xs-up, .hidden-sm-up, .hidden-md-up, .hidden-lg-up, 
  .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-md, .hidden-unless-xl
  {
    display: none !important;
  } 
}

/* Breakpoint XL */
@media (min-width: 1200px)
{
  .hidden-xl-down, 
  .hidden-xs-up, .hidden-sm-up, .hidden-md-up, .hidden-lg-up, .hidden-xl-up, 
  .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-md, .hidden-unless-lg
  {
    display: none !important;
  } 
}


@media (max-width: 576px) {
  h1 {
    font-size: 1.8rem !important;
  }


  h4 {
    font-size: 1.2rem !important;
    text-align: center !important;
  }
}


.line-filters {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  margin: 2rem 0;

  span:first-child {
    font-weight: bold;
    font-size: 14px;
    padding: 3px;
    color: map-get($colors, secondary);
  }
}